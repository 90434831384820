/*
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import GroupsModel from '@/model/local-repository/ldap/groups.model'
import UsersModel from '@/model/local-repository/ldap/users.model'
import RelationshipModel from '@/model/local-repository/ldap/relationship.model'
import RecurringSyncModel from '@/model/local-repository/ldap/recurringSync.model'

const SSL = 389
const TLS = 636

export {
  SSL,
  TLS
}

export default class LdapModel {
    /**
     * Creates an instance of LdapModel.
     * @param {Array} data - LdapModel list
     * @class LdapModel
     * @property {string} fileName - Ldap configuration file name
     * @property {string} name - Connection name
     * @property {string} url - Server host name
     * @property {number} port - Server port
     * @property {string} bindDN - Bind DN
     * @property {string} password - Password
     * @property {boolean} tls - Secure connection option
     * @property {Boolean} active - Connection  status
     * @property {Object} groups - Information about LDAP group querying and mapping
     * @property {Object} users - Information about LDAP users querying and mapping
     * @property {Object} relationship - Group and user relationship
     * @property {Object} recurringSync - Recurring sync
     */
    constructor (data = {}) {
      this.fileName = data.fileName || ''
      this.name = data.name || ''
      this.url = data.url || ''
      this.port = data.port || 389
      this.bindDN = data.bindDN || ''
      this.password = data.password || ''
      this.tls = data.tls || false
      this.active = data.active || false
      this.groups = new GroupsModel(data.groups) || {}
      this.users = new UsersModel(data.users) || {}
      this.relationship = new RelationshipModel(data.relationship) || {}
      this.recurringSync = new RecurringSyncModel(data.recurringSync) || {}
    }
}
