<!--
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form
    id="iiotLdapSynchronizationForm"
    ref="synchronizationForm">
    <v-card
      class="mx-auto"
      outlined
    >
      <v-list-item>
        <v-list-item-content>
          <h3 class="mb-5">{{ $t('ldap.ldapSynchronization.title') }}</h3>
          <v-list-item-subtitle>
            <v-row no-gutters>
              <v-col
                cols="12">
                <v-col
                  sm="4"
                  class="pa-0 pr-8">
                  <ldap-synchronization-groups
                    ref="groups"/>
                  <v-dialog
                    v-model="groupDialog"
                    persistent
                    max-width="1000"
                    class="dialog-max-height">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="iiotLdapTestGroupsSync"
                        :disabled="!isConnectionCorrect"
                        v-bind="attrs"
                        class="default-btn mt-6 ml-4"
                        @click="testGroupsSync()"
                      >
                        {{ $t('ldap.ldapSynchronization.testGroupsSync') }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <v-layout
                          class="title"
                          column>
                          <h1 class="pt-0">{{ $t('ldap.ldapSynchronization.testGroupsQuery') }}</h1>
                          <v-divider/>
                        </v-layout>
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          :headers="headersGroups"
                          :items="queryResponseGroups"
                          :loading="loadingGroups"
                          :loading-text="$t('ldap.ldapSynchronization.loading')"
                          class="elevation-1"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn
                          id="iiotLdapTestGroupsSyncCancel"
                          color="tertiary"
                          text
                          @click="groupDialog = false">
                          {{ $t('ldap.ldapSynchronization.close') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col
                  sm="4"
                  class="pa-0 pr-8">
                  <ldap-synchronization-users
                    ref="users"/>
                  <v-dialog
                    v-model="userDialog"
                    persistent
                    max-width="1000"
                    class="dialog-max-height">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="iiotLdapTestUsersSync"
                        :disabled="!isConnectionCorrect"
                        v-bind="attrs"
                        class="default-btn mt-6 ml-4"
                        @click="testUsersSync()"
                      >
                        {{ $t('ldap.ldapSynchronization.testUsersSync') }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <v-layout
                          class="title"
                          column>
                          <h1 class="pt-0">{{ $t('ldap.ldapSynchronization.testUsersQuery') }}</h1>
                          <v-divider/>
                        </v-layout>
                      </v-card-title>
                      <v-card-text>
                        <v-data-table
                          :headers="headersUsers"
                          :items="queryResponseUsers"
                          :loading="loadingUsers"
                          class="elevation-1"
                          loading-text="Loading... Please wait"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn
                          id="iiotLdapTestUsersSyncCancel"
                          color="tertiary"
                          text
                          @click="userDialog = false">
                          {{ $t('ldap.ldapSynchronization.close') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col
                  sm="4"
                  class="pa-0 pr-8">
                  <ldap-user-group-relationship
                    ref="relationship"/>
                </v-col>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-card
      class="mx-auto mt-5"
      outlined
    >
      <v-list-item>
        <v-list-item-content>
          <h3>{{ $t('ldap.ldapSynchronization.syncTitle') }}</h3>
          <v-list-item-subtitle class="pl-3 mt-5">
            <v-row class="recuring-div-height pl-1">
              <v-switch
                id="iiotLdapSynchronizationRecurringSync"
                :disabled="!isConnectionCorrect"
                v-model="ldapData.recurringSync.recurringSync"
                :label="$t('ldap.ldapSynchronization.recurringSync')"
                class="mx-2 mt-6"
              />
              <div
                v-if="ldapData.recurringSync.recurringSync"
                class="schedule-div-width ml-8 mb-4">
                <v-select
                  id="iiotLdapSynchronizationSchedule"
                  v-model="ldapData.recurringSync.schedule"
                  :items="scheduleItems"
                  :label="$t('ldap.ldapSynchronization.schedule')"
                  :disabled="!ldapData.recurringSync.recurringSync || !isConnectionCorrect"
                  :menu-props="{ maxHeight: '180' }"
                  attach
                  @change="changeSchedule()"
                />
              </div>
              <div
                v-if="ldapData.recurringSync.recurringSync"
                class="ml-8 mb-1">
                <div class="time-picker-div-width">
                  <v-menu
                    v-if="ldapData.recurringSync.schedule === 'Starting time'"
                    id="iiotLdapSynchronizationStartingTimeMenu"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="ldapData.recurringSync.time"
                    transition="scale-transition"
                    offset-y
                    max-width="220px"
                    min-width="220px"
                    attach
                    top
                  >
                    <template
                      v-slot:activator="{ on, attrs }">
                      <v-text-field
                        id="iiotLdapSynchronizationStartingTime"
                        v-model="ldapData.recurringSync.time"
                        v-bind="attrs"
                        :label="$t('ldap.ldapSynchronization.startingTime')"
                        :disabled="!ldapData.recurringSync.recurringSync || !isConnectionCorrect"
                        :rules="[rules.timeRequired]"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-model="ldapData.recurringSync.time"
                      full-width
                      @click:minute="$refs.menu.save(ldapData.recurringSync.time)"
                    />
                  </v-menu>
                  <v-text-field
                    v-if="ldapData.recurringSync.schedule === 'Interval'"
                    id="iiotLdapSynchronizationIntervalTime"
                    v-model="ldapData.recurringSync.time"
                    :label="$t('ldap.ldapSynchronization.intervalTime')"
                    :disabled="!ldapData.recurringSync.recurringSync || !isConnectionCorrect"
                    :rules="[rules.timeRequired, rules.timeIntervalRange]"
                    :min="0"
                    type="number"
                    prepend-icon="access_time"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  />
                </div>
              </div>
            </v-row>
            <v-fade-transition slot="append">
              <div v-if="ldapData.recurringSync.schedule === 'Starting time' && ldapData.recurringSync.recurringSync">
                <v-alert
                  outlined
                  type="warning"
                  border="left"
                  class="sync-info mt-5"
                  dense
                >
                  {{ $t('ldap.ldapSynchronization.syncInformation') }}
                </v-alert>
              </div>
            </v-fade-transition>
            <v-btn
              id="iiotLdapSynchronizationSyncButton"
              :loading="syncResponse"
              :disabled="!isConnectionCorrect || !ldapData.fileName"
              class="default-btn pl-2 mt-6"
              @click="sync()"
            >
              {{ $t('ldap.ldapSynchronization.sync') }}
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-form>
</template>

<script>
import ldapSynchronizationGroups from '@/components/ldap/ldap-synchronization-groups/ldapSynchronizationGroups.vue'
import ldapSynchronizationUsers from '@/components/ldap/ldap-synchronization-users/ldapSynchronizationUsers.vue'
import ldapUserGroupRelationship from '@/components/ldap/ldap-user-group-relationship/ldapUserGroupRelationship.vue'
import { VALIDATION_REGEX } from '@/constants'
export default {
  components: {
    ldapSynchronizationGroups,
    ldapSynchronizationUsers,
    ldapUserGroupRelationship
  },
  data: () => ({
    groups: 'groups',
    users: 'users',
    relationship: 'relationship',
    scheduleItems: ['Starting time', 'Interval'],
    time: null,
    menu: false,
    groupDialog: false,
    loadingGroups: false,
    headersUsers: [
      { text: 'First name', align: 'start', value: 'firstName' },
      { text: 'Last name', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Username', value: 'username' }
    ],
    headersGroups: [
      { text: 'Name', align: 'start', value: 'name' },
      { text: 'Description', value: 'description' }
    ],
    loadingUsers: false,
    userDialog: false
  }),
  computed: {
    rules () {
      return {
        timeRequired: value => !!value || this.$t('ldap.ldapSynchronization.timeRequired'),
        timeIntervalRange: value => VALIDATION_REGEX.INTERVAL_REGEX.test(value) || this.$t('ldap.ivalid_port_range')
      }
    },
    ldapData () {
      return this.$store.getters['ldap/getDefaultLdap']()
    },
    isConnectionCorrect () {
      return this.$store.getters['ldap/getStatusOfTestConnection']()
    },
    queryResponseGroups () {
      return this.$store.getters['ldap/queryResponseGroups']()
    },
    queryResponseUsers () {
      return this.$store.getters['ldap/queryResponseUsers']()
    },
    syncResponse () {
      return this.$store.getters['ldap/syncResponse']()
    }
  },
  methods: {
    async sync () {
      if (this.isSyncFormValid()) {
        await this.$store.dispatch('ldap/sync', this.$store.getters['ldap/getDefaultLdap']())
      }
    },
    async testGroupsSync () {
      if (this.isTestUsersFormValid()) {
        this.groupDialog = true
        await this.$store.dispatch('ldap/test_groups_sync', this.$store.getters['ldap/getDefaultLdap']())
      }
    },
    async testUsersSync () {
      if (this.isTestGroupsFormValid()) {
        this.userDialog = true
        await this.$store.dispatch('ldap/test_users_sync', this.$store.getters['ldap/getDefaultLdap']())
      }
    },
    async changeSchedule () {
      this.$store.dispatch('ldap/set_time_to_zero')
    },
    isSyncFormValid () {
      return (this.$refs.synchronizationForm.validate() &&
        this.$refs.groups.$refs.synchronizationGroupsForm.validate() &&
        this.$refs.users.$refs.synchronizationUsersForm.validate() &&
        this.$refs.relationship.$refs.userGroupRelationshipForm.validate())
    },
    isTestUsersFormValid () {
      return (this.$refs.groups.$refs.synchronizationGroupsForm.validate())
    },
    isTestGroupsFormValid () {
      return (this.$refs.users.$refs.synchronizationUsersForm.validate())
    }
  }
}
</script>
<style lang="scss" scoped>
  .schedule-div-width {
    width: 200px;
  }
  .time-picker-div-width {
    width: 110px
  }
  .recuring-div-height {
    height: 60px
  }
  .dialog-max-height {
    max-height: 500px !important
  }
  .sync-info {
    width: 550px;
  }
</style>
