// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
// Routes
import paths from './paths'

function route ({ path, view, name, meta }) {
  return {
    name: name || view,
    path,
    meta,
    component: async (resovle) => import(`@/views/${view}.vue`)
  }
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: paths.map(path => {
    return route(path)
  })
})

export default router
