// https://vuex.vuejs.org/en/modules.html
const requireModule = require.context('.', true, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  // Replace ./ and .js
  const path = fileName.replace(/(\.\/|\.js)/g, '')
  modules[path] = requireModule(fileName).default
  modules[path].namespaced = true
})

export default modules
