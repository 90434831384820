import Axios from '@/plugins/axios'

const METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
}

export default class BaseApiService {
  get (url, options) {
    return this.fetch({ method: METHODS.GET, url, ...options })
  }

  post (url, data, options) {
    return this.fetch({ method: METHODS.POST, url, data, ...options })
  }

  put (url, data, options) {
    return this.fetch({ method: METHODS.PUT, url, data, ...options })
  }

  delete (url, options) {
    return this.fetch({ method: METHODS.DELETE, url, ...options })
  }

  patch (url, data, options) {
    return this.fetch({ method: METHODS.PATCH, url, data, ...options })
  }

  async fetch (axiosFields) {
    try {
      this.setSessionHeader(axiosFields)
      const res = await Axios(axiosFields)
      return res.data ? res.data : res
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /*
  Set cloud auth header from local storage
   */
  setSessionHeader (axiosFields) {
    const session = JSON.parse(localStorage.getItem('session'))
    if (!axiosFields.headers) {
      axiosFields.headers = {}
    }

    axiosFields.headers.sessionId = session.sessionId
  }

  setHostname (hostname) {
    Axios.defaults.baseURL =
      location.protocol + '//' +
      hostname + ':' + location.port +
      Axios.defaults.baseURL.replace(new RegExp(`${location.protocol}//[^/]+/`), '/')
  }
}
