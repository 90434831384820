<template>
  <v-snackbar
    v-model="dialogState"
    :timeout="-1"
    :right="true"
    :top="true"
    :color="toastParams.color"
  >
    {{ $t(toastParams.text) }}
    <v-btn
      v-if="toastParams.showClose"
      text
      @click="closeToast()"
    >
      {{ $t('toast.closeBtn') }}
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ApiToast',
  computed: {
    dialogState: {
      get () {
        return this.$store.getters['utils/_api_request_handler/getToastState']()
      },
      set () {}
    },
    toastParams: {
      get () {
        return this.$store.getters['utils/_api_request_handler/getToastParams']()
      },
      set () {}
    }
  },
  methods: {
    closeToast () {
      this.$store.dispatch('utils/_api_request_handler/close_toast')
    }
  }
}
</script>
