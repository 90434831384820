// import router from '@/router'
import store from '@/store'
import axios from 'axios'

axios.defaults.baseURL = CONFIG.BASE_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status !== 401) {
      store.dispatch('utils/_api_request_handler/show_custom_toast',
      { text: `errorMessages.${error.response.data[0].errorCode}`, color: 'red' })
    }
    // if (router.currentRoute.name !== 'Login') {
    //   await store.dispatch('auth/clear_user')
    //   await router.push({ name: 'Login' })
    //   store.dispatch('utils/_api_request_handler/show_custom_toast',
    //     { text: 'login.notLoggedIn', color: 'red' })

    //   return Promise.resolve(error) // resolve this error to prevent all dialogs showing
    // }
  })

export default axios
