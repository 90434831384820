/**
 * @property {number} MAX_LENGTH_CLOUD_URL
 * @property {number} CHARACTER_COUNTER
 * @property {number} SERIAL_NUMBER_LENGTH
 * @property {number} UNIT_OF_INFORMATION
 * @property {number} URL_LENGTH
 * @property {String} NETWORK_CONFIGURATION
 * @property {String} MAX_LENGTH_REPOSITORY_URL
 * @property {Object} REPOSITORY_USERNAME
 * @property {Object} REPOSITORY_PASSWORD
 * @property {Object} IP_ADDRESS
 * @property {Object} VALIDATION_REGEX
 * @property {Object} PROTOCOLS
 */

const MAX_LENGTH_CLOUD_URL = 255
const CHARACTER_COUNTER = 12
const SERIAL_NUMBER_LENGTH = 12
const UNIT_OF_INFORMATION = 1000
const URL_LENGTH = 100
const NETWORK_CONFIGURATION = 'static'
const MAX_LENGTH_REPOSITORY_URL = 255

const VALIDATION_REGEX = {
  SERIAL_NUMBER: /^[0-9A-Z]*$/,
  NEW_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
  NO_CONTROL_CHARACTERS: /^[^\u0000-\u001f\u007f-\u009f]*$/,
  PORT_RANGE: /^0*([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-4])$/,
  INTERVAL_REGEX: /^([1-9]|[12][0-9]|3[01])*$/
}

const PROTOCOLS = {
  WSS: 'wss',
  SSL: 'ssl',
  HTTP: 'http',
  HTTPS: 'https'
}

const IP_ADDRESS = {
  MIN_LENGTH: 0,
  MAX_LENGTH: 255
}

const REPOSITORY_USERNAME = {
  MIN_LENGTH: 2,
  MAX_LENGTH: 40
}

const REPOSITORY_PASSWORD = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 40
}

export {
  MAX_LENGTH_CLOUD_URL,
  CHARACTER_COUNTER,
  SERIAL_NUMBER_LENGTH,
  UNIT_OF_INFORMATION,
  URL_LENGTH,
  NETWORK_CONFIGURATION,
  MAX_LENGTH_REPOSITORY_URL,
  REPOSITORY_USERNAME,
  REPOSITORY_PASSWORD,
  IP_ADDRESS,
  VALIDATION_REGEX,
  PROTOCOLS
}
