<!--
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div class="ldap-page-padding">
    <v-layout
      class="title"
      column>
      <h1>{{ $t('ldap.title') }}</h1>
      <v-divider/>
    </v-layout>
    <v-row>
      <v-switch
        id="iiotLdapAuthentication"
        v-model="ldapData.active"
        :label="$t('ldap.ldapAuthentication')"
        :disabled="ldapActiveResponse || authState"
        class="mx-2 auth-switch"
        @click="changeLdapActivation()"
      />
      <v-progress-circular
        v-if="ldapActiveResponse"
        class="mt-3"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-form
      id="iiotLdapForm"
      ref="form"
      @submit.prevent="submitForm">
      <v-row>
        <v-col
          cols="12">
          <ldap-connection
            ref="connection"
          />
          <ldap-synchronization
            ref="synchronization"
            class="mt-5"/>
        </v-col>
      </v-row>
      <v-layout
        class="mb-5">
        <v-btn
          id="iiotLdapSave"
          :loading= "saveResponse"
          :disabled="!isConnectionCorrect"
          type="submit"
          class="default-btn mt-5 ml-8"
        >
          {{ $t('baseForm.saveBtn') }}
        </v-btn>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import LdapConnection from '@/components/ldap/ldap-connection/LdapConnection.vue'
import LdapSynchronization from '@/components/ldap/ldap-synchronization/LdapSynchronization.vue'

export default {
  components: {
    LdapConnection,
    LdapSynchronization
  },
  data: () => ({
    connection: 'connection',
    synchronization: 'synchronization',
    ldapAuthDialogTitle: ''
  }),
  computed: {
    ldapData () {
      return this.$store.getters['ldap/getDefaultLdap']()
    },
    isConnectionCorrect () {
      return this.$store.getters['ldap/getStatusOfTestConnection']()
    },
    saveResponse () {
      return this.$store.getters['ldap/saveResponse']()
    },
    ldapActiveResponse () {
      return this.$store.getters['ldap/ldapActiveResponse']()
    },
    authState () {
      return this.$store.getters['ldap/authState']()
    }
  },
  created () {
    this.$store.dispatch('ldap/get_default_ldap')
    this.$store.dispatch('ldap/set_test_connection_status', localStorage.getItem('connectionStatus'))
  },
  methods: {
    async submitForm () {
      const model = this.ldapData
      if (!this.isLdapFormValid()) {
        return
      }

      // Filename is used as ID, so if it's not present
      // New configuration will be created
      if (model.fileName === '') {
        return this.$store.dispatch('ldap/create_ldap', model)
      }

      return this.$store.dispatch('ldap/update_ldap', model)
    },
    async changeLdapActivation () {
      this.ldapAuthDialogTitle = this.ldapData.active ? 'ldap.activeAuthDialog' : 'ldap.deactiveAuthDialog'
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: this.ldapAuthDialogTitle,
        callback: async () => {
          this.$store.dispatch('utils/_api_request_handler/close_confirm')
          await this.$store.dispatch('ldap/change_ldap_activation', this.$store.getters['ldap/getDefaultLdap']())
        },
        cancelCallback: () => {
          this.ldapData.active = !this.ldapData.active
        }
      })
    },
    isLdapFormValid () {
      return (this.$refs.form.validate() && this.$refs.connection.$refs.connectionForm.validate() &&
        this.$refs.synchronization.$refs.synchronizationForm.validate() &&
        this.$refs.synchronization.$refs.groups.$refs.synchronizationGroupsForm.validate() &&
        this.$refs.synchronization.$refs.users.$refs.synchronizationUsersForm.validate() &&
        this.$refs.synchronization.$refs.relationship.$refs.userGroupRelationshipForm.validate())
    }
  }
}
</script>

<style lang="scss" scoped>
    .auth-switch {
      width: 255px;
    }
    .ldap-page-padding {
      padding-left: 45px;
      padding-right: 45px;
    }
</style>
