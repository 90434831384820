<template>
  <v-dialog
    v-model="dialogState"
    scrollable
    max-width="320px"
    @click:outside="e => handler(e)"
    @keydown.esc="e => handler(e)">
    <v-card>
      <v-card-text
        class="mt-3">
        {{ $t(dialogParams.title) }}
      </v-card-text>

      <v-divider class="mt-0"/>

      <v-card-actions class="center-actions">
        <v-btn
          color="primary"
          class="button"
          @click="close()">{{ $t('dialog.cancel') }}</v-btn>
        <v-btn
          color="primary"
          class="button"
          @click="confirm()">{{ $t('dialog.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  computed: {
    dialogState: {
      get () {
        return this.$store.getters['utils/_api_request_handler/getConfirmDialogState']()
      },
      set () {
        this.close()
      }
    },
    dialogParams () {
      return this.$store.getters['utils/_api_request_handler/getConfirmDialogParams']()
    }
  },
  methods: {
    async close () {
      if (this.dialogParams.cancelCallback) {
        await this.dialogParams.cancelCallback()
      }
      this.$store.dispatch('utils/_api_request_handler/close_confirm')
    },
    async confirm () {
      await this.dialogParams.callback()
      this.$store.dispatch('utils/_api_request_handler/close_confirm')
    },
    async handler (event) {
      this.close()
    }
  }
}
</script>
<style scoped>
  .center-actions {
    margin: auto !important;
  }
  .button {
    text-transform: none;
    color: var(--v-secondary-base) !important;
    font-weight: bold !important;
  }
</style>
