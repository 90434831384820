import Vue from 'vue'
import i18n from '@/i18n'

import { UNIT_OF_INFORMATION } from '@/constants'

Vue.filter('digitalSpaceUnit', (unit, totalMB) => {
  if (!isHigherThanGB(totalMB)) {
    return i18n.t('dashboard.units.mb')
  }
  return isHigherThanTB(totalMB) ? i18n.t('dashboard.units.tb') : i18n.t('dashboard.units.gb')
})

Vue.filter('digitalSpaceConverter', (value, base) => {
  if (Number(value) === 0 || !isHigherThanGB(base)) {
    return value
  }

  if (isHigherThanTB(value)) {
    return (Number(value) / UNIT_OF_INFORMATION / UNIT_OF_INFORMATION).toFixed(1)
  } else {
    return (Number(value) / UNIT_OF_INFORMATION).toFixed(1)
  }
})

const isHigherThanGB = (totalMB) => {
  return Number(totalMB) > UNIT_OF_INFORMATION
}

const isHigherThanTB = (totalMB) => {
  return Number(totalMB) > UNIT_OF_INFORMATION * UNIT_OF_INFORMATION
}
