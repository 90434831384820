<!--
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-layout>
    <v-col class="pa-0">
      <v-card
        class="mx-auto"
        outlined
      >
        <v-list-item>
          <v-list-item-content>
            <h3 class="mb-5">{{ $t('ldap.ldapConnection.title') }}</h3>
            <v-list-item-subtitle class="pl-6">
              <v-form
                id="iiotLdapConnectionForm"
                ref="connectionForm"
                @submit.prevent="testConnection">
                <v-row>
                  <div class="field-width mr-8">
                    <v-text-field
                      id="iiotLdapConnectionName"
                      v-model="ldapData.name"
                      :rules="[rules.required, rules.no_control_characters]"
                      :label="$t('ldap.ldapConnection.name')"
                    />
                    <v-col class="pt-0">
                      <v-row>
                        <v-text-field
                          id="iiotLdapConnectionUrl"
                          v-model="ldapData.url"
                          :rules="[rules.required, rules.no_control_characters]"
                          :label="$t('ldap.ldapConnection.url')"
                        />
                        <span class="ml-2 mr-2 mt-6 font-size-large"><b>:</b></span>
                        <v-text-field
                          id="iiotLdapConnectionPort"
                          v-model="ldapData.port"
                          :rules="[rules.portRequired, rules.port_range]"
                          :label="$t('ldap.ldapConnection.port')"
                          :min="0"
                          class="server-port-width"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                      </v-row>
                    </v-col>
                  </div>
                  <div class="field-width">
                    <v-text-field
                      id="iiotLdapBindDN"
                      v-model="ldapData.bindDN"
                      :rules="[rules.required, rules.no_control_characters]"
                      :label="$t('ldap.ldapConnection.bindDN')"
                    />
                    <password-field
                      id="iiotLdapPassword"
                      v-model="ldapData.password"
                      :label="$t('ldap.ldapConnection.password') "
                    />
                    <v-switch
                      id="iiotLdapSecureConnectionOption"
                      v-model="ldapData.tls"
                      :label="$t('ldap.ldapConnection.secureConnectionOption')"
                      class="width-secure-connection-field"
                      @change="changeSecureConnectionOption()"
                    />
                  </div>
                </v-row>
                <v-row class="field-width">
                  <div>
                    <v-btn
                      id="iiotLdapTestConnection"
                      :loading="testConnectionResponse"
                      class="default-btn mt-0"
                      @click="testConnection()"
                    >
                      {{ $t('ldap.ldapConnection.testConnection') }}
                    </v-btn>
                  </div>
                  <div>
                    <v-img
                      v-if="isConnectionCorrect"
                      id="iiotLdapStatusOk"
                      src="/resources/images/online.svg"
                      class="icon-div mt-2"/>
                    <v-img
                      v-if="isConnectionCorrect === false"
                      id="iiotLdapStatusNotOk"
                      src="/resources/images/offline.svg"
                      class="icon-div mt-2"/>
                  </div>
                </v-row>
              </v-form>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-layout>
</template>

<script>
import PasswordField from '@/components/shared/PasswordField.vue'
import { REPOSITORY_PASSWORD, VALIDATION_REGEX } from '@/constants'
import { SSL, TLS } from '@/model/local-repository/ldap/ldap.model'
export default {
  components: {
    PasswordField
  },
  data: () => ({
    REPOSITORY_PASSWORD,
    VALIDATION_REGEX,
    formReadyCounter: 0,
    SSL,
    TLS
  }),
  computed: {
    rules () {
      return {
        required: value => !!value || this.$t('ldap.ldapConnection.required'),
        portRequired: value => !!value || this.$t('ldap.ldapConnection.portRequired'),
        no_control_characters: value => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('ldap.noControlCharacter'),
        port_range: value => VALIDATION_REGEX.PORT_RANGE.test(value) || this.$t('ldap.ivalid_port_range')
      }
    },
    ldapData () {
      return this.$store.getters['ldap/getDefaultLdap']()
    },
    isConnectionCorrect () {
      return this.$store.getters['ldap/getStatusOfTestConnection']()
    },
    testConnectionResponse () {
      return this.$store.getters['ldap/testConnectionResponse']()
    }
  },
  watch: {
    'ldapData.url' () {
      this.watchData()
    },
    'ldapData.port' () {
      this.watchData()
    },
    'ldapData.name' () {
      this.watchData()
    },
    'ldapData.password' () {
      this.watchData()
    },
    'ldapData.bindDN' () {
      this.watchData()
    }
  },
  updated () {
    this.formReadyCounter = 5
  },
  methods: {
    async testConnection () {
      if (this.$refs.connectionForm.validate()) {
        await this.$store.dispatch('ldap/test_connection', this.$store.getters['ldap/getDefaultLdap']())
        this.formReadyCounter = 5
      }
    },
    changeSecureConnectionOption () {
      this.ldapData.port = this.ldapData.tls ? this.TLS : this.SSL
    },
    watchData () {
      this.formReadyCounter++
      if (this.formReadyCounter > 5) {
        this.$store.dispatch('ldap/set_test_connection_status_without_save', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .field-width {
    width: 350px
  }
  .server-port-width {
    width: 50px
  }
  .font-size-large {
    font-size: large
  }
  .icon-div {
    height: 20px;
    width: 20px;
  }
  .width-secure-connection-field {
    width: 290px;
  }
</style>
