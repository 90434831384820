<template>
  <v-dialog
    v-model="dialogState"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">{{ $t(dialogParams.title) }}</v-card-title>
      <v-card-text>{{ $t(dialogParams.text) }} {{ (dialogParams.list || []).join(', ') }}</v-card-text>
      <v-card-actions v-if="!dialogParams.noClose">
        <v-spacer/>
        <v-btn
          color="default-btn ma-0 pa-0"
          flat
          @click="closeDialog()"
        >
          {{ $t('errorDialog.okBtn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApiMessageBox',
  computed: {
    dialogState: {
      get () {
        return this.$store.getters['utils/_api_request_handler/getMessageBoxState']()
      },
      set () {}
    },
    dialogParams: {
      get () {
        return this.$store.getters['utils/_api_request_handler/getMessageBoxParams']()
      },
      set () {}
    }
  },
  methods: {
    closeDialog () {
      this.$store.dispatch('utils/_api_request_handler/close_message_box')
    }
  }
}
</script>
