export default {
  dark: false,
  options: {
    customProperties: true
  },
  themes: {
    light: {
      primary: '#2cc1ff',
      secondary: '#ffffff',
      tertiary: '#081a25',
      accent: '#0093d0',
      error: '#701825',
      info: '#00d3ee',
      success: '#5cb860',
      warning: '#ffa21a',
      gray: '#9c9c9c',
      TTTechOrange: '#ff6100'
    },
    dark: {
      primary: '#4caf50',
      secondary: '#081a25',
      tertiary: '#ffffff',
      accent: '#82B1FF',
      error: '#701825',
      info: '#00d3ee',
      success: '#5cb860',
      warning: '#ffa21a',
      TTTechBlue: '#0093d0',
      TTTechOrange: '#ff6100'
    }
  }
}
