<!--
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form
    id="iiotLdapSynchronizationGroupsForm"
    ref="synchronizationGroupsForm">
    <v-row no-gutters>
      <v-col
        cols="12">
        <v-col
          sm="7">
          <h3 class="mb-5">{{ $t('ldap.ldapSynchronization.groups') }}:</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="iiotLdapSynchronizationGroupsSearchBase"
                :disabled="!isConnectionCorrect"
                v-model="ldapData.groups.searchBase"
                :rules="[rules.required, rules.no_control_characters]"
                :label="$t('ldap.ldapSynchronization.searchBase')"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span>{{ ldapData.groups.searchBase }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="iiotLdapSynchronizationGroupsFilter"
                v-model="ldapData.groups.filter"
                :disabled="!isConnectionCorrect"
                :rules="[rules.required, rules.no_control_characters]"
                :label="$t('ldap.ldapSynchronization.filter')"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span>{{ ldapData.groups.filter }}</span>
          </v-tooltip>
          <v-text-field
            id="iiotLdapSynchronizationGroupName"
            :disabled="!isConnectionCorrect"
            v-model="ldapData.groups.name"
            :rules="[rules.required, rules.no_control_characters]"
            :label="$t('ldap.ldapSynchronization.groupName')"
          />
        </v-col>
        <v-col
          sm="5"
          class="mt-9">
          <v-text-field
            id="iiotLdapSynchronizationGroupAdminGroup"
            :disabled="!isConnectionCorrect"
            v-model="ldapData.groups.adminGroup"
            :rules="[rules.required, rules.no_control_characters]"
            :label="$t('ldap.ldapSynchronization.adminGroup')"
          />
          <v-select
            id="iiotLdapSynchronizationGroupSelectDefaultRole"
            :disabled="!isConnectionCorrect"
            v-model="ldapData.groups.default"
            :items="roles"
            :rules="[rules.required]"
            :label="$t('ldap.ldapSynchronization.defaultRole')"
            :menu-props="{ maxHeight: '180' }"
            attach
          />
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants'
export default {
  data: () => ({
    VALIDATION_REGEX
  }),
  computed: {
    rules () {
      return {
        required: value => !!value || this.$t('ldap.ldapSynchronization.required'),
        no_control_characters: value => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('ldap.noControlCharacter')
      }
    },
    ldapData () {
      return this.$store.getters['ldap/getDefaultLdap']()
    },
    roles () {
      return this.$store.getters['ldap/getRoles']()
    },
    isConnectionCorrect () {
      return this.$store.getters['ldap/getStatusOfTestConnection']()
    }
  }
}
</script>
