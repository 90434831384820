// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
// Plugins
import '@/styles/index.scss'
import './plugins'
import vuetify from '@/plugins/vuetify' // path to vuetify export

// Filters
import './filters'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

// Sync store with router
sync(store, router)

Vue.config.productionTip = false
/*
 Attach Vue dependencies the window object so ngVue can inject them as plugins.
 */
window.Vue = Vue
window.store = store
window.router = router
window.i18n = i18n
/*
  Attach App component to the window object where all the views from view folder are loaded,
  so components can be included with the ngVue library
 */
window.vueApp = App
window.vuetify = vuetify

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
})
