<template>
  <v-app>
    <div id="core-view">
      <slot/> <!-- since we use angularjs router, we will use this to wrap views we add until we go full view -->
    </div>
    <api-toast/>
    <api-message-box/>
    <api-loading-bar/>
    <confirm-dialog/>
  </v-app>
</template>

<script>
import ApiToast from '@/components/api-dialogs/ApiToast'
import ApiMessageBox from '@/components/api-dialogs/ApiMessageBox'
import ApiLoadingBar from '@/components/api-dialogs/ApiLoadingBar'
import ConfirmDialog from '@/components/api-dialogs/Confirm'

export default {
  components: {
    ApiToast,
    ApiMessageBox,
    ApiLoadingBar,
    ConfirmDialog
  },
  metaInfo () {
    return {
      title: this.$t('app.title')
    }
  }
}
</script>
