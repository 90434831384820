import store from '@/store'

const defaultToastParams = {
  text: '',
  showClose: true,
  color: ''
}
const defaultMessageBoxParams = {
  title: '',
  text: '',
  list: [],
  noClose: false,
  redirect: false
}

export default {

  state: {
    loadingBar: false,
    toast: false,
    toastParams: defaultToastParams,
    messageBox: false,
    messageBoxParams: defaultMessageBoxParams,
    confirmDialog: false,
    confirmDialogParams: {
      title: '',
      callback: Function,
      cancelCallback: Function
    }
  },
  mutations: {
    SHOW_LOADING_BAR: state => { state.loadingBar = true },
    SHOW_TOAST: (state, params) => {
      if (state.toast) clearTimeout(state.toast)
      state.toastParams = Object.assign(defaultToastParams, params)
      state.toast = setTimeout(function () { state.toast = false }, 6000)
    },
    SHOW_MESSAGE_BOX: (state, params) => {
      state.messageBoxParams = Object.assign(defaultMessageBoxParams, params)
      state.messageBox = true
    },
    CLOSE_LOADING_BAR: state => { state.loadingBar = false },
    CLOSE_TOAST: state => {
      if (state.toast) clearTimeout(state.toast)
      state.toast = false
    },
    CLOSE_MESSAGE_BOX: state => {
      state.messageBox = false
      state.messageBoxParams = defaultMessageBoxParams
    },
    CLOSE_CONFIRM: state => { state.confirmDialog = false },
    SHOW_CONFIRM: (state, params) => {
      state.confirmDialog = true
      state.confirmDialogParams = params
    }
  },
  getters: {
    getLoadingBarState: state => () => state.loadingBar,
    getToastState: state => () => state.toast,
    getToastParams: state => () => state.toastParams,
    getMessageBoxState: state => () => state.messageBox,
    getMessageBoxParams: state => () => state.messageBoxParams,
    getConfirmDialogState: state => () => state.confirmDialog,
    getConfirmDialogParams: state => () => state.confirmDialogParams
  },
  actions: {
    show_loading_bar ({ commit }) {
      commit('SHOW_LOADING_BAR')
    },

    show_toast ({ commit }) {
      commit('SHOW_TOAST', { text: 'toast.text', color: 'success' })
    },

    show_custom_toast ({ commit }, params) {
      commit('SHOW_TOAST', params)
    },

    show_message_box ({ commit }, params) {
      commit('SHOW_MESSAGE_BOX', params)
    },

    show_permanent_message ({ commit }) {
      commit('SHOW_MESSAGE_BOX', { title: 'interfaceDialog.title', text: 'interfaceDialog.text', noClose: true })
    },

    show_error_dialog ({ commit }) {
      commit('SHOW_MESSAGE_BOX', { title: 'errorDialog.title', text: 'errorDialog.text' })
    },

    close_loading_bar ({ commit }) {
      commit('CLOSE_LOADING_BAR')
    },

    close_toast ({ commit }) {
      commit('CLOSE_TOAST')
    },

    close_message_box ({ state, commit }) {
      commit('CLOSE_MESSAGE_BOX')
      if (state.messageBoxParams.redirect) store.dispatch('network-interface/redirect')
    },

    show_confirm_dialog ({ commit }, params) {
      commit('SHOW_CONFIRM', params)
    },

    close_confirm ({ commit }) {
      commit('CLOSE_CONFIRM')
    }
  }
}
