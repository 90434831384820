<template>
  <svg
    width="50"
    height="52"
    xmlns="http://www.w3.org/2000/svg">

    <g>
      <rect
        id="canvas_background"
        fill="none"
        height="52"
        width="52"
        y="-1"
        x="-1"/>
    </g>
    <g>
      <path
        id="svg_1"
        fill="#3698d3"
        d="m15.344613,35.353758l0,4.572c0.151,0.54 3.15,2 9,2s8.839,-1.455 9,-2.012l0,-4.56c-2.128,1.072 -5.643,1.572 -9,1.572s-6.872,-0.5 -9,-1.572zm10,5.572l-2,0l0,-2l2,0l0,2z"/>
      <path
        id="svg_2"
        fill="#3698d3"
        d="m33.251806,19.668217c-0.2,-0.566 -3.2,-2 -9,-2c-5.841,0 -8.839,1.455 -9,2.012l0,0.006c0.161,0.526 3.159,1.982 9,1.982c5.8,0 8.8,-1.434 9,-2z"/>
      <path
        id="svg_3"
        stroke="null"
        fill="#3698d3"
        d="m4.445372,21.719262l10.30958,0l0,-3.120054c0,-2.143477 4.897051,-3.120054 9.450449,-3.120054s9.450449,0.976577 9.450449,3.120054l0,3.120054l6.013922,0a8.591317,7.800134 0 0 0 0,-15.600268l-0.52407,0a0.859132,0.780013 0 0 1 -0.810161,-0.519489a8.149723,7.399207 0 0 0 -15.097521,-0.620111a0.859132,0.780013 0 0 1 -1.390934,0.222304a8.161751,7.410127 0 0 0 -13.965186,5.20737l0,2.730047a0.859132,0.780013 0 0 1 -0.859132,0.780013l-2.577395,0a4.295658,3.900067 0 0 0 0,7.800134zm40.379189,-5.460094l-1.718263,0l0,-1.560027l1.718263,0l0,1.560027zm-5.15479,-6.240107a5.159945,4.684761 0 0 1 5.15479,4.680081l-1.718263,0a3.436527,3.120054 0 0 0 -3.436527,-3.120054l0,-1.560027z"/>
      <path
        id="svg_4"
        fill="#3698d3"
        d="m33.251806,28.446565c-2.128,1.067 -5.643,1.572 -9,1.572s-6.872,-0.505 -9,-1.572l0,4.572c0.151,0.54 3.15,2 9,2s8.839,-1.455 9,-2.012l0,-4.56zm-8,5.572l-2,0l0,-2l2,0l0,2z"/>
      <path
        id="svg_5"
        fill="#3698d3"
        d="m33.158998,21.446565c-2.128,1.072 -5.643,1.572 -9,1.572s-6.872,-0.5 -9,-1.572l0,4.572c0.151,0.54 3.15,2 9,2s8.839,-1.455 9,-2.012l0,-4.56zm-8,5.572l-2,0l0,-2l2,0l0,2z"/>
      <path
        id="svg_6"
        stroke="null"
        fill="#3698d3"
        d="m48.033712,47.482972l-20.4405,0a3.127611,3.594988 0 0 0 -2.234678,-2.568619l0,-2.823863l-1.563805,0l0,2.823863a3.127611,3.594988 0 0 0 -2.234678,2.568619l-20.4405,0l0,1.797494l20.4405,0a3.112755,3.577911 0 0 0 6.033161,0l20.4405,0l0,-1.797494zm-23.45708,2.696241a1.563805,1.797494 0 1 1 1.563805,-1.797494a1.563805,1.797494 0 0 1 -1.563805,1.797494z"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'DatapathIcon'
  }
</script>
