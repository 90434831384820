import Vue from 'vue'
import Vuetify from 'vuetify'

import theme from './theme'
import { en, de } from 'vuetify/es5/locale'

import GrafanaIcon from '@/components/icons/GrafanaIcon.vue'
import DatapathIcon from '@/components/icons/DatapathIcon'
Vue.use(Vuetify)

const opts = {
  theme,
  lang: {
    locales: { en, de },
    current: 'en'
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      grafana: {
        component: GrafanaIcon
      },
      datapath: {
        component: DatapathIcon
      }
    }
  }
}

export default new Vuetify(opts)
