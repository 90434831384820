<!--
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form
    id="iiotLdapSynchronizationUsersForm"
    ref="synchronizationUsersForm">
    <v-row no-gutters>
      <v-col
        cols="12">
        <v-col sm="7">
          <h3 class="mb-5">{{ $t('ldap.ldapSynchronization.users') }}:</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="iiotLdapSynchronizationUsersSearchBase"
                :disabled="!isConnectionCorrect"
                v-model="ldapData.users.searchBase"
                :rules="[rules.required, rules.no_control_characters]"
                :label="$t('ldap.ldapSynchronization.searchBase')"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span>{{ ldapData.users.searchBase }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="iiotLdapSynchronizationUsersFilter"
                :disabled="!isConnectionCorrect"
                v-model="ldapData.users.filter"
                :rules="[rules.required, rules.no_control_characters]"
                :label="$t('ldap.ldapSynchronization.filter')"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span>{{ ldapData.users.filter }}</span>
          </v-tooltip>
          <v-text-field
            id="iiotLdapSynchronizationUserFirstName"
            :disabled="!isConnectionCorrect"
            v-model="ldapData.users.firstName"
            :rules="[rules.required, rules.no_control_characters]"
            :label="$t('ldap.ldapSynchronization.firstname')"
          />
        </v-col>
        <v-col
          sm="5"
          class="mt-9">
          <v-text-field
            id="iiotLdapSynchronizationUserLastName"
            :disabled="!isConnectionCorrect"
            v-model="ldapData.users.lastName"
            :rules="[rules.required, rules.no_control_characters]"
            :label="$t('ldap.ldapSynchronization.lastname')"
          />
          <v-text-field
            id="iiotLdapSynchronizationEmail"
            :disabled="!isConnectionCorrect"
            v-model="ldapData.users.email"
            :rules="[rules.required, rules.no_control_characters]"
            :label="$t('ldap.ldapSynchronization.email')"
          />
          <v-text-field
            id="iiotLdapSynchronizationUserUsername"
            :disabled="!isConnectionCorrect"
            v-model="ldapData.users.username"
            :rules="[rules.required, rules.no_control_characters]"
            :label="$t('ldap.ldapSynchronization.username')"
          />
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants'
export default {
  data: () => ({
    VALIDATION_REGEX
  }),
  computed: {
    rules () {
      return {
        required: value => !!value || this.$t('ldap.ldapSynchronization.required'),
        no_control_characters: value => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('ldap.noControlCharacter')
      }
    },
    ldapData () {
      return this.$store.getters['ldap/getDefaultLdap']()
    },
    isConnectionCorrect () {
      return this.$store.getters['ldap/getStatusOfTestConnection']()
    }
  }
}
</script>
