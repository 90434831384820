<template>
  <v-dialog
    v-model="dialogState"
    persistent
    width="300"
  >
    <v-card
      color="TTTechBlue"
      dark
    >
      <v-card-text>
        {{ $t('loadingBar.text') }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ApiLoadingBar',
  computed: {
    dialogState: {
      get () {
        return this.$store.getters['utils/_api_request_handler/getLoadingBarState']()
      },
      set () {}
    }
  }
}
</script>
