import store from '@/store'
import { NodesApiService } from '@/services/api'
import NodeModel from '@/model/local-repository/node.model'

export default {
  state: {
    nodeData: {
      name: '',
      serialNumber: '',
      secureId: '',
      model: 'mfn-100'
    }
  },
  mutations: {
    SET_NODE_DATA (state, nodeData) {
      state.nodeData = nodeData
    }
  },
  getters: {
    getNodeData: state => () => new NodeModel(state.nodeData)
  },
  actions: {

    async change_node_info ({ commit }, nodeData) {
      try {
        store.dispatch('utils/_api_request_handler/close_toast')
        store.dispatch('utils/_api_request_handler/show_loading_bar')
        await NodesApiService.setNodeData(nodeData)
        commit('SET_NODE_DATA', nodeData)
        store.dispatch('utils/_api_request_handler/show_toast')
      } catch (e) {
        console.error(e)
        store.dispatch('utils/_api_request_handler/show_error_dialog')
      } finally {
        store.dispatch('utils/_api_request_handler/close_loading_bar')
      }
    }
  }
}
