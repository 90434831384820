import store from '@/store'
import { LdapApiService } from '@/services/api'
import LdapModel from '@/model/local-repository/ldap/ldap.model'

export default {
  state: {
    defaultLdap: new LdapModel(),
    roles: [],
    isConnectionCorrect: null,
    queryResponseGroups: [],
    queryResponseUsers: [],
    testConnectionResponse: false,
    saveResponse: false,
    syncResponse: false,
    ldapActiveResponse: false,
    authState: false
  },
  getters: {
    getDefaultLdap: state => () => state.defaultLdap,
    getRoles: state => () => state.roles,
    getStatusOfTestConnection: state => () => state.isConnectionCorrect,
    queryResponseGroups: state => () => state.queryResponseGroups,
    queryResponseUsers: state => () => state.queryResponseUsers,
    testConnectionResponse: state => () => state.testConnectionResponse,
    saveResponse: state => () => state.saveResponse,
    syncResponse: state => () => state.syncResponse,
    ldapActiveResponse: state => () => state.ldapActiveResponse,
    authState: state => () => state.authState
  },
  mutations: {
    SET_DEFAULT_LDAP (state, defaultLdap) {
      state.defaultLdap = new LdapModel(defaultLdap)
    },
    SET_ROLES (state, roles) {
      state.roles = roles.data.map(role => role.name)
    },
    UPDATE_LDAP (state, ldap) {
      Object.assign(state.defaultLdap, ldap)
    },
    CREATE_LDAP (state, ldap) {
      Object.assign(state.defaultLdap, ldap)
    },
    UPDATE_TEST_CONNECTION_STATUS (state, status) {
      localStorage.setItem('connectionStatus', status)
      if (typeof status === 'string' && status !== 'null') {
        state.isConnectionCorrect = (status === 'true')
        return
      }
      state.isConnectionCorrect = status
    },
    UPDATE_TEST_CONNECTION_STATUS_WITHOUT_SAVE (state, status) {
      state.isConnectionCorrect = status
    },
    UPDATE_LDAP_ACTIVATION_STATUS (state, status) {
      state.defaultLdap.active = status
    },
    UPDATE_TIME_TO_ZERO (state) {
      state.defaultLdap.recurringSync.time = null
    },
    RESPONSE_GROUPS (state, res) {
      state.queryResponseGroups = res
    },
    RESPONSE_USERS (state, res) {
      state.queryResponseUsers = res
    },
    TEST_CONNECTION_RESPONSE (state, value) {
      state.testConnectionResponse = value
    },
    SAVE_RESPONSE (state, value) {
      state.saveResponse = value
    },
    SYNC_RESPONSE (state, value) {
      state.syncResponse = value
    },
    LDAP_ACTIVE_RESPONSE (state, value) {
      state.ldapActiveResponse = value
    },
    CHANGE_AUTH_STATE (state, value) {
      state.authState = value
    }
  },
  actions: {
    async get_default_ldap ({ commit }) {
      try {
        commit('SET_ROLES', await LdapApiService.getAllRoles({ filterBy: { type: 'local' }, limit: 0, page: 0 }))
        commit('SET_DEFAULT_LDAP', await LdapApiService.getDefaultLdap())
        commit('CHANGE_AUTH_STATE', false)
      } catch (e) {
        commit('SET_DEFAULT_LDAP', [])
        commit('UPDATE_TEST_CONNECTION_STATUS', null)
        commit('CHANGE_AUTH_STATE', true)
        console.error('Get default ldap  error', e)
      }
    },
    async create_ldap ({ commit }, ldap) {
      try {
        commit('SAVE_RESPONSE', true)
        const response = await LdapApiService.create(ldap)
        commit('CREATE_LDAP', response)
        commit('SAVE_RESPONSE', false)
        commit('CHANGE_AUTH_STATE', false)
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'ldap.createLdapMessage', color: '#0093d0'
        })
      } catch (e) {
        console.error('Create ldap error', e)
        commit('SAVE_RESPONSE', false)
        commit('CHANGE_AUTH_STATE', true)
      }
    },
    async update_ldap ({ commit }, ldap) {
      try {
        commit('SAVE_RESPONSE', true)
        await LdapApiService.update(ldap)
        commit('UPDATE_LDAP', ldap)
        commit('SAVE_RESPONSE', false)
        localStorage.setItem('isLdapActive', ldap.active)
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'ldap.updateLdapMessage', color: '#0093d0'
        })
      } catch (e) {
        console.error('Update ldap error', e)
        commit('SAVE_RESPONSE', false)
      }
    },
    async test_groups_sync ({ commit }, ldap) {
      try {
        const res = await LdapApiService.testGroupsSync(ldap)
        commit('RESPONSE_GROUPS', res)
        return res
      } catch (e) {
        commit('RESPONSE_GROUPS', [])
        console.error('Test groups sync', e)
      }
    },
    async test_users_sync ({ commit }, ldap) {
      try {
        const res = await LdapApiService.testUsersSync(ldap)
        commit('RESPONSE_USERS', res)
        return res
      } catch (e) {
        commit('RESPONSE_USERS', [])
        console.error('Test groups sync', e)
      }
    },
    async test_connection ({ commit }, ldap) {
      try {
        commit('TEST_CONNECTION_RESPONSE', true)
        const res = await LdapApiService.testConnection(ldap)
        commit('UPDATE_TEST_CONNECTION_STATUS', res.connected)
        commit('TEST_CONNECTION_RESPONSE', false)
      } catch (e) {
        console.error('Test connection', e)
        commit('TEST_CONNECTION_RESPONSE', false)
      }
    },
    set_test_connection_status ({ commit }, status) {
      try {
        commit('UPDATE_TEST_CONNECTION_STATUS', status)
      } catch (e) {
        console.error('Set test connection status', e)
      }
    },
    set_test_connection_status_without_save ({ commit }, status) {
      try {
        commit('UPDATE_TEST_CONNECTION_STATUS_WITHOUT_SAVE', status)
      } catch (e) {
        console.error('Set test connection status without save', e)
      }
    },
    async sync ({ commit }, ldap) {
      try {
        commit('SYNC_RESPONSE', true)
        await LdapApiService.sync(ldap)
        commit('SYNC_RESPONSE', false)
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'ldap.syncLdapMessage', color: '#0093d0'
        })
      } catch (e) {
        console.error('Sync', e)
        commit('SYNC_RESPONSE', false)
      }
    },
    async change_ldap_activation ({ commit }, ldap) {
      try {
        commit('LDAP_ACTIVE_RESPONSE', true)
        await LdapApiService.setActiveLdap({ name: ldap.fileName, active: ldap.active })
        commit('UPDATE_LDAP_ACTIVATION_STATUS', ldap.active)
        localStorage.setItem('isLdapActive', ldap.active)
        if (ldap.active) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'ldap.LdapActivated', color: '#0093d0'
          })
        } else {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'ldap.LdapDeactivated', color: '#0093d0'
          })
        }
        commit('LDAP_ACTIVE_RESPONSE', false)
      } catch (e) {
        commit('UPDATE_LDAP_ACTIVATION_STATUS', !ldap.active)
        console.error('Change ldap activation', e)
        commit('LDAP_ACTIVE_RESPONSE', false)
      }
    },
    set_time_to_zero ({ commit }) {
      try {
        commit('UPDATE_TIME_TO_ZERO')
      } catch (e) {
        console.error('Set time to zero', e)
      }
    }
  }
}
