/*
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

export default class NodeModel {
  /**
   * Creates an instance of NodeModel.
   * @param {Array} data - NodeModel list
   * @class NodeModel
   * @property {string} name - Username
   * @property {string} serialNumber - Password
   * @property {number} secureId - Repository Id
   * @property {number} model - Priority
   */
  constructor (data = {}) {
    this.name = data.name || ''
    this.serialNumber = data.serialNumber || ''
    this.secureId = data.secureId || ''
    this.model = data.model || ''
  }
}
