<template>
  <div id="passwordField">
    <v-text-field
      :append-icon="icon"
      :type="type"
      :label="label"
      :hint="hint"
      :min="min"
      :maxlength="max"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      v-model="inputValue"
      validate-on-blur
      color="TTTechBlue"
      @click:append="togglePassword"
      @input="emitValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    },
    required: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hidePassword: true,
      inputValue: this.value
    }
  },
  computed: {
    type () {
      return this.hidePassword ? 'password' : 'text'
    },
    icon () {
      return this.hidePassword ? 'mdi-eye' : 'mdi-eye-off'
    }
  },
  watch: {
    value (newValue) {
      this.inputValue = newValue
    }
  },
  methods: {
    togglePassword () {
      this.hidePassword = !this.hidePassword
    },
    emitValue (event) {
      this.$emit('input', this.inputValue)
    }
  }
}
</script>
