import BaseApiService from './base.api-service'

const PATH = '/node'

class NodesApiService extends BaseApiService {
  setNodeData (data) {
    return this.post(PATH, data)
  }
}

export default new NodesApiService()
