import { set, toggle } from '@/utils/vuex'
export default {
  state: {
    drawer: false,
    color: 'TTTechBlue',
    image: '',
    sidebarBackgroundColor: 'rgba(27, 27, 27, 0.74)'
  },
  mutations: {
    SET_DRAWER: set('drawer'),
    SET_IMAGE: set('image'),
    SET_COLOR: set('color'),
    TOGGLE_DRAWER: toggle('drawer')
  }
}
