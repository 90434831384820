<template>
  <v-app/>
</template>
<script>
/*
 require all view components from view folder so we don't have to do
 it manually, hopefully we will use hybrid only for views, not separate components
 */
const requireView = require.context(
  '@/views', true, /\.vue$/
)

const components = requireView.keys().reduce((components, viewPath) => {
  const pathArray = viewPath.split('/')
  const viewName = pathArray[pathArray.length - 1].replace('.vue', '')
  components[viewName] = requireView(viewPath)
  return components
}, {})

export default {
  components
}
</script>

<style lang="scss">

body > *{
   font-family: 'Exo2 Regular'!important;
   /*background: white!important;*/
 }
input > *{
  font-family: 'Exo2 Regular'!important;
}

/* Remove in 1.2 */
</style>
