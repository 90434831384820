<!--
*  TTTech nerve-local-ui
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-container fill-height>
    <v-layout>
      <v-flex
        xs12
        md8
        class="add-node-page">
        <v-layout
          class="title"
          column>
          <h1>{{ $t('addUpdateNode.title', { operation: $t('addUpdateNode.add') }) }}</h1>
        </v-layout>
        <v-form
          ref="form"
          class="pa-0"
          @submit.prevent="submitForm"
          @keyup.enter.native="submitForm">
          <v-flex>
            <v-text-field
              v-model="nodeData.name"
              :label="$t('addUpdateNode.name')"
              :rules="[rules.required]"
              :maxlength="MAX_LENGTH_CLOUD_URL"
              :reverse="true"
              type="text"
              validate-on-blur
            />
          </v-flex>
          <v-text-field
            v-model="nodeData.serialNumber"
            :label="$t('addUpdateNode.serialNumber')"
            :rules="[rules.required, rules.serialNumberPattern, rules.exactLength, rules.serialNumberInvalid]"
            :maxlength="CHARACTER_COUNTER"
            :counter="CHARACTER_COUNTER"
            type="text"
            class="pa-0"
            validate-on-blur
          />
          <v-text-field
            v-model="nodeData.secureId"
            :label="$t('addUpdateNode.secureId')"
            :rules="[rules.required, rules.serialNumberPattern, rules.serialNumberInvalid]"
            :maxlength="16"
            :counter="16"
            type="text"
            class="pa-0"
            validate-on-blur
          />
          <v-btn
            id="cancelBtn"
            class="newDefaultBtn cancel"
            @click="$router.go(-1)"
          >
            {{ $t('baseForm.cancelBtn') }}
          </v-btn>
          <v-btn
            id="saveBtn"
            type="submit"
            class="newDefaultBtn"
          >
            {{ $t('baseForm.saveBtn') }}
          </v-btn>
        </v-form>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import isFQDN from 'validator/lib/isFQDN'
import isIP from 'validator/lib/isIP'
import { MAX_LENGTH_CLOUD_URL, CHARACTER_COUNTER, SERIAL_NUMBER_LENGTH, URL_LENGTH, VALIDATION_REGEX, PROTOCOLS } from '@/constants'
import { NodeConfigApiService } from '@/services/api'
import store from '@/store'

export default {
  data: () => ({
    valid: false,
    MAX_LENGTH_CLOUD_URL,
    CHARACTER_COUNTER,
    URL_LENGTH,
    PROTOCOLS
  }),
  computed: {
    nodeData () {
      return store.getters['nodes/getNodeData']()
    },

    rules () {
      return {
        required: value => !!value || this.$t('nodeConfiguration.required'),
        exactLength: value => value.length === SERIAL_NUMBER_LENGTH || this.$t('nodeConfiguration.exactLength'),
        domainPattern: value => (isFQDN(value) || isIP(value)) || this.$t('nodeConfiguration.invalidInput'),
        serialNumberPattern: value => VALIDATION_REGEX.SERIAL_NUMBER.test(value) || this.$t('nodeConfiguration.invalidInput'),
        serialNumberInvalid: value => value !== '000000000000' || this.$t('nodeConfiguration.invalidInput'),
        protocolPattern: value => value === PROTOCOLS.WSS || value === PROTOCOLS.SSL || this.$t('nodeConfiguration.defaultValue')
      }
    },

    getIcon () {
      return `$vuetify.icons.${this.cloudVersion.current ? 'online' : 'offline'}`
    }
  },
  methods: {
    async showSecureId () {
      try {
        this.$store.dispatch('utils/_api_request_handler/show_loading_bar')
        const response = await NodeConfigApiService.getSecureId()
        this.isSecureIdDialogShown = true
        this.secureId = response.secureId
      } catch (e) {
        console.error(e)
        this.isSecureIdDialogShown = false
        this.$store.dispatch('utils/_api_request_handler/show_error_dialog')
      } finally {
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar')
      }
    },
    async submitForm () {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('nodes/change_node_info', this.nodeData)
        this.$emit('form-submitted')
      }
    }
  }
}
</script>

<style lang="scss">
  .add-node-page {
    padding-top: 100px;
  }
</style>
