import BaseApiService from './base.api-service'

const PATH = 'nerve/ldap'

class LdapApiService extends BaseApiService {
  getAll () {
    return this.get(PATH)
  }

  getOne (name) {
    return this.get(`${PATH}/${name}`)
  }

  create (data) {
    return this.post(PATH, data)
  }

  update (data) {
    return this.put(`${PATH}/${data.fileName}`, data)
  }

  deleteOne (name) {
    return this.delete(`${PATH}/${name}`)
  }

  testConnection (ldap) {
    return this.post(`${PATH}/connection/test`, ldap)
  }

  getActiveLdaps () {
    return this.get(`${PATH}/active`)
  }

  setActiveLdap (data) {
    return this.patch(`${PATH}/${data.name}/active`, { active: data.active })
  }

  getDefaultLdap () {
    return this.get(`${PATH}/default`)
  }

  getAllRoles (params) {
    return this.get(`nerve/rbac/roles`, { params })
  }

  testGroupsSync (ldap) {
    return this.post(`${PATH}/query/groups`, ldap)
  }

  testUsersSync (ldap) {
    return this.post(`${PATH}/query/users`, ldap)
  }

  sync (ldap) {
    return this.post(`${PATH}/sync`, ldap)
  }
}

export default new LdapApiService()
